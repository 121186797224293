.container {
    width: 80%;
    margin: auto;
    margin-bottom: 100px;
}

.flex-wrapper {
    display: flex;
    gap: 8%;
    flex-wrap: wrap;
}

.flex-left {
    width: 53%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: start;
    min-height: 280px;
    justify-content: space-between;
}

.flex-left p {
    text-align: initial;
    font-size: calc(0.15vw + 14px);
    color: #252525;
    width: 85%;
}

.flex-left button {
    background: #FFD500;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.03);
    border-radius: 12px;
    letter-spacing: 1.5px;
    color: #005BBB;
    padding: 1.8% 15%;
    font-weight: 900;
    border: none;
    font-size: calc(0.3vw + 14px);
    cursor: pointer;
}

.flex-left h1 {
    font-weight: 700;
    font-size: calc(1vw + 20px);
    line-height: 32px;
    letter-spacing: 2.5px;
    text-align: initial;
    color: #005BBB;
}

.gif {
    width: 39%;
    min-width: 250px;
    min-height: 180px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    position: relative;
}


.gify {
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0px 4px 30px 3px rgba(0, 0, 0, 0.15);
}

.items-end {
    align-self: flex-end;
}

.items-end button {
    cursor: pointer;
}