html, body {
    height: 100%;
} 
 
 .body  {
    font-family: 'Montserrat';
    color: #ffffff;
    background-color: rgba(0, 91, 187, 0.65);;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.container-term h1 {
    color: #ffffff!important;
    text-align: center;
    font-weight: 700;
    font-size: calc(0.8vw + 18px);
    letter-spacing: 0.02em;
}

h3 {
    margin-bottom: 2.5%;
    font-weight: 200;
    font-size: calc(0.4vw + 12px);
}

li {
    margin-bottom: 2%;
    font-weight: 200;
    font-size: calc(0.4vw + 12px);
}


li:nth-child(1):before {
    content: 'Acceptance of the terms';
}

li:nth-child(2):before {
    content: 'Amendments';
}

li:nth-child(3):before {
    content: 'Age limitation';
}

li:nth-child(4):before {
    content: 'Scope of service';
}

li:nth-child(5):before {
    content: 'License';
}

li:nth-child(6):before {
    content: 'Restriction of use';
}

li:nth-child(7):before {
    content: 'Your reprensentations and warranties';
}

li:nth-child(8):before {
    content: 'Updates';
}

li:nth-child(9):before {
    content: 'Ownership and intellectual property rights';
}

li:nth-child(10):before {
    content: 'Disclaimer of warranty';
}

li:nth-child(11):before {
    content: 'Indemnification';
}

li:nth-child(12):before {
    content: 'Termination';
}

li:nth-child(13):before {
    content: 'Privacy policy';
}

li:nth-child(14):before {
    content: 'Dispute resolution';
}

li:nth-child(15):before {
    content: 'Miscellaneous';
}

li:nth-child(16):before {
    content: 'Contact information';
}

li:before {
display: list-item;
list-style-image: url('../image/dot.svg');
text-decoration: underline;
text-decoration-color: #FFD400;
text-decoration-thickness: 3px;
letter-spacing: 0.05em;
font-weight: 400;
font-size: calc(0.5vw + 16px);
margin-bottom: 1%;
}

.container-term {
    width: 75%;
    margin: auto;
}

.logo-terms {
    padding-top: 1.95em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
}

.logo-terms-terms {
    width: 120px;
    max-width: 100%;
    height: auto;
}