html {
  font-family: 'Montserrat';
}

body {
  margin: 0;
}

.App {
  text-align: center;
}

.logo {
  width: 35%;
  margin-bottom: 5%;
}

.slick-prev:before, .slick-next:before {
  color: black;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.hero-black {
  padding: 6% 0% 8% 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#search-form {
  width: 100%;
  display: flex;
}

#input-search {
  border-radius: 12px 0px 0px 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px -4px 4px rgba(235, 235, 235, 0.01);
  padding: 12px;
  width: 80%;
  border: 0.125em solid #005BBB;
}

.btn-search {
  border-radius: 0px 12px 12px 0px;
  background: #005BBB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.03);
  cursor: pointer;
  padding: 8.5px 0px 12.1px 0px;
  font-family: 'Montserrat';
  width: 20%;
  font-size: 1.2em;
  font-weight: 600;
  color: #FFD500;
  border: none;
  /************* Make hover effect on the button *************/
  background-repeat: no-repeat;
  background-position: -500px -120px, 0 0;
  background-image: -webkit-linear-gradient(top left, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 37%, rgba(255, 255, 255, 0.8) 45%, rgba(255, 255, 255, 0.0) 50%);
  background-size: 250% 250%, 100% 100%;
  -webkit-transition: background-position 0s ease;
  transition: background-position 0s ease;
}

/*Activate hover button*/
.btn-search:hover {
  background-position: 0 0, 0 0;
  -webkit-transition-duration: 0.7s;
  -moz-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

.mid-hero {
  width: 85%;
  min-width: 250px;
  max-width: 700px;
}

.search-line form {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
}

.text-label, .btn-search {
  letter-spacing: 1.3px;
}

.text-label {
  display: block;
  margin-bottom: 75px;
  color: #005BBB;
}

.bottom-hero {
  color: #005BBB;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  min-height: 85px;
  opacity: .85;
}

.tis-80 {
  width: 80%;
}

.bottom-hero h2 {
  font-size: calc(2em + 12px);
  color: #FFD500;
  font-weight: bold;
  opacity: 1;
}

.test-black {
  overflow-x: hidden;
  height: 20vh;
  min-height: 320px;
  background-color: #252525;
}

.flex-hero {
  display: flex;
  overflow: hidden;
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

.flex-boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
}

.title-orgaz {
  font-weight: 700;
  font-size: calc(1vw + 20px);
  line-height: 32px;
  letter-spacing: 2.5px;
  text-align: initial;
  color: #005BBB;
  margin-bottom: 25px;
}

.organize-box {
  min-height: 50px;
  min-width: 300px;
  padding: 80px 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
}


.orga-1 {
  background-image: url('../src/image/organization-ifrc.png');
}

.orga-2 {
  background-image: url('../src/image/organization-pah.png');
  background-size: 55%;
}

.orga-3 {
  background-image: url('../src/image/organization-come-back-alive.png');
  background-size: 40%;
}

.orga-1::after, .orga-2::after, .orga-3::after, .orga-4::after, .orga-5::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #005BBB;
  opacity: 0.15;
  cursor: pointer;
  border-radius: 5px;
  transition: all ease-in-out 0.35s;
}


.orga-1::before {
  content: "ifrc.org "'  'url('../src/image/export.png');
}

.orga-2::before {
  content: "pah.org.pl "'  'url('../src/image/export.png');
}

.orga-3::before {
  content: "comebackalive.in.ua "'  'url('../src/image/export.png');
}
/*
.orga-4::before {
  content: "nbcdi.org "'  'url('../src/image/export.png');
}

.orga-5::before {
  content: "innocenceproject.org "'  'url('../src/image/export.png');
}*/

.orga-1::before, .orga-2::before, .orga-3::before, .orga-4::before, .orga-5::before {
  z-index: 99;
  color: #fff;
  position: absolute;
  opacity: 0;
  transition: all ease-in-out 0.35s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: calc(0.9vw + 2.5px);
  width: 100%;
  text-align: center;
}

.orga-1:hover:after, .orga-2:hover:after, .orga-3:hover:after, .orga-4:hover:after, .orga-5:hover:after {
  opacity: 0.92;
}

.orga-1:hover:before, .orga-2:hover:before, .orga-3:hover:before, .orga-4:hover:before, .orga-5:hover:before {
  opacity: 1 !important;
}

.container-organization {
  padding: 25px 150px 100px 150px;
}

.flex-wrap {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  justify-content: space-between;
}

.flex {
  display: flex;
  flex-direction: column;
}

.cursor-orgaz {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  gap: 10px;
}




.donate-container {
  background-size: contain;
  position: relative;
  background-position: center right;
  background-repeat: no-repeat;
  height: 35vh;
  min-height: 350px;
  padding: 25px;
  margin-bottom: 100px;
}

.text-donate {
  color: #fff;
  width: 40%;
  text-align: justify;
  font-size: calc(0.9vw + 2.5px);
  font-weight: 300;
  opacity: 0.95;
}

.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  border: none;
  width: 100%;
  height: 100%;
  align-items: center;
  background: rgba(0, 91, 187, 0.65);
}

.flex-left h2 {
  color: #fff;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 3px;
  font-size: calc(0.25vw + 16px);
  text-align: initial;
}

.overlay .flex-left p {
  color: #fff;
}

.btn-black {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 190px;
  color: #fff;
  background: #252525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(235, 235, 235, 0.03);
  border-radius: 12px;
  font-weight: 500;
  font-size: calc(0.9vw + 2.5px);
}

.margin-left {
  margin-left: 10%;
}

.space-evenly {
  justify-content: space-evenly !important;
}

.flex-left h1 span {
  text-decoration: underline;
  cursor: pointer;
}

.flex-wrapping {
  display: flex;
  gap: 8%;
  flex-wrap: wrap;
}

.flex-right {
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: initial;
}

.elipse {
  width: 55%;
  height: auto;
  margin-top: 50px;
}

.text-view {
  text-align: initial;
  font-size: calc(0.15vw + 14px);
  color: #252525;
  width: 85%;

}

.reports-desciption {
  text-align: initial;
  font-size: calc(0.15vw + 14px);
  color: #252525;
  width: 90%;
  color: #252525;
  font-weight: 400;
}

.details {
  font-style: italic;
  font-weight: 400;
  font-size: calc(0.15vw + 14px);
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: rgba(37, 37, 37, 0.85);
}

.dropdown {
  display: inline;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  right: 0;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}


/***************** FOOTER ****************/

.foot {
  background: rgba(0, 91, 187, 0.65);
  padding: 4% 0% 1% 0%;
  color: #fff;
}

.container-foot {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
}

.foot-top {
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  justify-content: space-between;
}

.top-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top-left h1 {
  font-weight: 700;
  font-size: calc(1vw + 20px);
  line-height: 24px;
  letter-spacing: 2.5px;
  text-align: initial;
  margin-bottom: 15px;
}

.top-left h2 {
  font-weight: 500;
  font-size: calc(0.55vw + 14px);
  line-height: 30px;
  letter-spacing: 1.5px;
  text-align: initial;
  margin-bottom: 15px;
}


.top-left button {
  background: #FFD500;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%), 0px -4px 4px rgb(235 235 235 / 3%);
  border-radius: 12px;
  letter-spacing: 1.5px;
  color: #005BBB;
  padding: 1.8% 15%;
  font-weight: 900;
  border: none;
  font-size: calc(0.3vw + 14px);
  cursor: pointer;
}

.top-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-right img {
  width: 25%;
}

.flexing {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
}

.flexing a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.foot-bottom p {
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: calc(0.2vw + 12px);
  line-height: 27px;
  letter-spacing: 4px;
}

.hover-1:after, .hover-2:after, .hover-3:after {
  content: '';
  width: 0px;
  height: 2px;
  display: block;
  background: #fff;
  transition: 300ms;
}

.hover-1:hover:after, .hover-2:hover:after, .hover-3:hover:after {
  width: 100%;
}

/* scroll to top button */


.scrollToTopBtn {
  background-color: #2A2A2C;
  border: 2px solid #fff;
  color: white;
  cursor: pointer;
  font-size: 18px;
  line-height: 48px;
  width: 48px;
  height: 48px;
  border-radius: 35%;
  /* place it at the bottom right corner */
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* keep it at the top of everything else */
  z-index: 100;
  /* hide with opacity */
  opacity: 0;
  /* also add a translate effect */
  transform: translateY(100px);
  /* and a transition */
  transition: transform 0.5s, opacity 0.5s ease;
}


.showBtn {
  opacity: 1;
  transform: translateY(0);
}


.scrollToTopBtn svg {
  display: block;
  margin: auto;
}

.scrollToTopBtn:hover svg polygon {
  fill: #DDBBF3;
}

.scrollToTopBtn:hover {
  border-color: #DDBBF3;
}

.counter-top { 
  display: flex;
  position: absolute;
  top: 20px;
  left:25px;
  width: 12%;
  min-width: 200px;
  justify-content: space-between;
  border-bottom: 2px solid rgba(37, 37, 37, 0.04);
  align-items: center;
}

.counter-top span {
  color: #252525;
  font-weight: 700;
}

.counter-top p {
  color: #252525;
  letter-spacing: 1.3px;
  font-weight: 300;
}

.counter-top .info {
  margin-bottom: 5px;
}